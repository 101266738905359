<template>
  <register-container>
    <template slot="header">{{ pageTitle || thankyouTitle }}</template>
    <template slot="subtitle">
      <div class="text-center mt-2" v-if="!pageSubtitle">
        {{ thankyouSubtitle1 }}<br />
        {{ thankyouSubtitle2 }}
      </div>
      <div class="text-center mt-2" v-else>
        {{ pageSubtitle }}
      </div>
    </template>
    <div class="thankyou-container d-flex justify-center mt-3">
      <img class="thankyou-image" src="../images/register-thankyou.jpg" />
    </div>
  </register-container>
</template>
<style scoped>
.thankyou-container {
  width: 100%;
}

@media all and (max-width: 600px) {
  .thankyou-image {
    max-width: 95%;
  }
}
</style>
<script>
import RegisterContainer from '../components/RegisterContainer.vue';

export default {
  name: 'RegisterThankyou',
  components: { RegisterContainer },
  data() {
    return {
      thankyouSubtitle1: this.$t('registration.registerThankyou.subtitle1'),
      thankyouSubtitle2: this.$t('registration.registerThankyou.subtitle2'),
      thankyouTitle: this.$t('registration.registerThankyou.title'),
    };
  },
  methods: {
    getInitialState() {
      const config = this.$store.getters.getConfig(this.$options.name);
      if (!config) return;
      Object.keys(config).forEach((option) => {
        this[option] = config[option];
      });
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.getInitialState();
    });
  },
};
</script>
